import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { ethers } from "ethers";
import { useWallet } from "../contexts/WalletContext";
import { contestContractAbis } from "../contracts/abi/contractAbis";
import tokenData from "../contracts/abi/V2/Token.json";

const tokenAbi = tokenData.abi;

const useContest = (contest) => {
  const [contract, setContract] = useState(null);
  const { selectedAccount, isWalletConnected } = useWallet();

  const initializeContract = useCallback(async () => {
    if (!window.ethereum) {
      toast.error(
        "Ethereum wallet is not available. Please connect and select an account in MetaMask."
      );
      return null;
    }

    if (!isWalletConnected || !selectedAccount) {
      toast.info("Please connect MetaMask and select an account.");
      return null;
    }

    if (window.ethereum.selectedAddress !== selectedAccount) {
      toast.error("Selected wallet and MetaMask account do not match.");
      return null;
    }

    const contestFactoryAddress = contest.factoryAddress;
    const contestAbi = contestContractAbis[contestFactoryAddress];

    if (contest.contractAddress && contestAbi) {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contestContract = new ethers.Contract(
        contest.contractAddress,
        contestAbi,
        signer
      );
      return contestContract;
    } else {
      toast.error("Contract address or ABI is missing.");
      return null;
    }
  }, [contest, selectedAccount, isWalletConnected]);

  const getOrInitializeContract = useCallback(async () => {
    if (!contract) {
      const initializedContract = await initializeContract();
      if (initializedContract) {
        setContract(initializedContract);
        return initializedContract;
      }
      return null;
    }
    return contract;
  }, [contract, initializeContract]);

  const approveToken = useCallback(async () => {
    if (!contest.tokenAddress) {
      toast.error("Token contract address is missing.");
      return false;
    }

    if (contest.tokenAddress === "0x0000000000000000000000000000000000000000") {
      return true; // No approval needed for native ETH
    }

    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const tokenContract = new ethers.Contract(
        contest.tokenAddress,
        tokenAbi,
        signer
      );

      const entryFee = ethers.parseUnits(contest.entryFee.toString(), 18);
      const votingFee = ethers.parseUnits(contest.votingFee.toString(), 18);
      const totalApprovalAmount = entryFee.add(votingFee);

      const currentAllowance = await tokenContract.allowance(
        selectedAccount,
        contest.contractAddress
      );

      if (currentAllowance.lt(totalApprovalAmount)) {
        const approvalTx = await tokenContract.approve(
          contest.contractAddress,
          totalApprovalAmount
        );
        const approvalReceipt = await approvalTx.wait();
        toast.success("Token approval successful!");
        return true;
      } else {
        return true; // No need for a new approval transaction
      }
    } catch (error) {
      handleError(error, "Token approval failed");
      return false;
    }
  }, [contest, selectedAccount]);

  // Helper function to handle errors
  const handleError = (error, defaultMessage) => {
    console.error("Error object:", error);

    if (error.data && error.data.message) {
      const revertReason = error.data.message;
      console.error("Revert reason:", revertReason);
      toast.error(revertReason);
    } else if (error.reason) {
      console.error("Error reason:", error.reason);
      toast.error(error.reason);
    } else if (error.message) {
      console.error("Error message:", error.message);
      toast.error(defaultMessage || error.message);
    } else {
      toast.error(defaultMessage || "An unknown error occurred");
    }
  };

  const submitEntry = useCallback(
    async (imageData) => {
      const contract = await getOrInitializeContract();

      if (!contract) {
        console.error("Error: Contract not initialized.");
        return;
      }

      const isApproved = await approveToken();
      if (!isApproved) {
        console.log("Token not approved. Submission aborted.");
        return;
      }

      try {
        // Listen for contract events
        contract.on("SubmissionMade", (wallet, image) => {
          console.log("SubmissionMade event:", wallet, image);
          toast.success("Submission made successfully!");
        });

        let txResponse;
        if (
          contest.tokenAddress === "0x0000000000000000000000000000000000000000"
        ) {
          const value = ethers.parseUnits(contest.entryFee.toString(), 18);
          txResponse = await contract.submitEntry(imageData, { value });
        } else {
          txResponse = await contract.submitEntry(imageData);
        }

        const txReceipt = await txResponse.wait();
        toast.success("Entry submitted successfully!");
        return txReceipt;
      } catch (error) {
        handleError(error, "Failed to execute transaction.");
      } finally {
        // Remove event listeners to prevent memory leaks
        contract.removeAllListeners("SubmissionMade");
      }
    },
    [contract, approveToken, selectedAccount]
  );

  const voteForSubmission = useCallback(
    async (submissionIndex) => {
      const contract = await getOrInitializeContract();

      if (!contract) {
        console.error("Error: Contract not initialized.");
        return;
      }

      const isApproved = await approveToken();
      if (!isApproved) {
        console.log("Token not approved. Voting aborted.");
        return;
      }

      try {
        // Listen for contract events
        contract.on("VoteCast", (voter, submissionIndex, image) => {
          console.log("VoteCast event:", voter, submissionIndex, image);
          toast.success("Vote cast successfully!");
        });

        let txResponse;
        if (
          contest.tokenAddress === "0x0000000000000000000000000000000000000000"
        ) {
          const value = ethers.parseUnits(contest.votingFee.toString(), 18);
          txResponse = await contract.voteForSubmission(submissionIndex, {
            value,
          });
        } else {
          txResponse = await contract.voteForSubmission(submissionIndex);
        }

        const txReceipt = await txResponse.wait();
        toast.success("Vote cast successfully!");

        try {
          const response = await axios.post(
            "https://app.dankmymeme.xyz:443/api/votes",
            {
              contestId: contest._id,
              voter: selectedAccount,
              submissionIndex,
              txHash: txReceipt.hash,
            }
          );

          if (
            response.data &&
            response.data.message === "Vote recorded successfully"
          ) {
            console.log(
              "Vote recorded successfully in the database:",
              response.data
            );
          } else {
            console.error(
              "Error recording vote in the database:",
              response.data
            );
          }
        } catch (apiError) {
          console.error("Error recording vote to the database:", apiError);
        }

        return txReceipt;
      } catch (error) {
        handleError(error, "Failed to execute transaction.");
      } finally {
        // Remove event listeners to prevent memory leaks
        contract.removeAllListeners("VoteCast");
      }
    },
    [contract, approveToken, selectedAccount]
  );

  const endContest = useCallback(
    async (contestId) => {
      const contract = await getOrInitializeContract();

      if (!contract) {
        console.error("Error: Contract not initialized.");
        return;
      }

      const isApproved = await approveToken();
      if (!isApproved) {
        console.log("Token not approved. Ending contest aborted.");
        return;
      }

      try {
        // Listen for contract events
        contract.on("ContestEnded", (winner, winnerPrize, luckyVoterPrize) => {
          console.log(
            "ContestEnded event:",
            winner,
            winnerPrize,
            luckyVoterPrize
          );
          toast.success(
            `Contest Ended. Winner: ${winner}, Prize: ${winnerPrize}, Lucky Voter Prize: ${luckyVoterPrize}`
          );
        });

        const txResponse = await contract.endContest();
        const txReceipt = await txResponse.wait();
        toast.success("Contest ended successfully on the blockchain!");

        try {
          const response = await axios.patch(
            `https://app.dankmymeme.xyz:443/api/contests/${contestId}/end`
          );
          console.log("Database updated successfully:", response.data);
          toast.success("Contest ended successfully in the database!");
        } catch (dbError) {
          console.error("Error updating the database:", dbError);
          toast.error("Failed to update the contest status in the database.");
        }

        return txReceipt;
      } catch (error) {
        handleError(error, "Failed to execute transaction.");
      } finally {
        // Remove event listeners to prevent memory leaks
        contract.removeAllListeners("ContestEnded");
      }
    },
    [contract, approveToken, selectedAccount]
  );

  const withdrawUnclaimedPrize = useCallback(async () => {
    const contract = await getOrInitializeContract();

    if (!contract) {
      toast.error("Contract not initialized.");
      return;
    }

    try {
      // Listen for contract events
      contract.on("WithdrawalFailed", () => {
        toast.error("Withdrawal failed!");
      });

      const txResponse = await contract.withdrawUnclaimedPrize();
      const txReceipt = await txResponse.wait();
      toast.success("Unclaimed prize withdrawn successfully!");
      return txReceipt;
    } catch (error) {
      handleError(error, "Failed to withdraw prize.");
    } finally {
      // Remove event listeners to prevent memory leaks
      contract.removeAllListeners("WithdrawalFailed");
    }
  }, [contract]);

  const updateContestParameters = useCallback(
    async (entryFee, votingFee, winnerPercentage, numberOfLuckyVoters) => {
      const contract = await getOrInitializeContract();

      if (!contract) {
        toast.error("Contract not initialized.");
        return;
      }

      try {
        // Listen for contract events
        contract.on(
          "ParametersUpdated",
          (entryFee, votingFee, winnerPercentage, numberOfLuckyVoters) => {
            toast.success("Contest parameters updated successfully!");
          }
        );

        const txResponse = await contract.updateContestParameters(
          entryFee,
          votingFee,
          winnerPercentage,
          numberOfLuckyVoters
        );
        const txReceipt = await txResponse.wait();
        return txReceipt;
      } catch (error) {
        handleError(error, "Failed to update parameters.");
      } finally {
        // Remove event listeners to prevent memory leaks
        contract.removeAllListeners("ParametersUpdated");
      }
    },
    [contract]
  );

  const submitMeme = useCallback(
    async (file, selectedAccount) => {
      if (!file || !selectedAccount) {
        toast.error("Invalid file or wallet not connected");
        return {
          success: false,
          message: "Invalid file or wallet not connected",
        };
      }

      try {
        const formData = new FormData();
        formData.append("file", file);

        const ipfsResponse = await axios.post(
          "https://app.dankmymeme.xyz:443/api/pinFile",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        const ipfsHash = ipfsResponse.data.IpfsHash;

        const txReceipt = await submitEntry(ipfsHash);

        if (!txReceipt) {
          return {
            success: false,
            message: "Failed to record submission. Please try again later",
          };
        }

        const dbResponse = await axios.post(
          "https://app.dankmymeme.xyz:443/api/submissions",
          {
            contest,
            userAddress: selectedAccount,
            ipfsHash,
          }
        );

        if (dbResponse.status === 200) {
          toast.success("Submission successfully recorded in the database");
          return {
            success: true,
            message: "Submission successfully recorded in the database",
          };
        } else {
          toast.error("Error recording submission in the database");
          return {
            success: false,
            message: "Error recording submission in the database",
          };
        }
      } catch (error) {
        handleError(error, "Error submitting meme to blockchain or database");
        return {
          success: false,
          message: "Error submitting meme to blockchain or database",
        };
      }
    },
    [submitEntry]
  );

  return {
    submitEntry,
    voteForSubmission,
    submitMeme,
    endContest,
    withdrawUnclaimedPrize,
    updateContestParameters,
  };
};

export default useContest;
