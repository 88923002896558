// src/contracts/contractAbis.js
import factoryAbiV1 from "./V2/ContestFactory.json";
import factoryAbiV2 from "./V2/ContestFactory.json";
import factoryAbiV3 from "./V3/ContestFactoryV3.json";
import contestAbiV1 from "./V2/Contest.json";
import contestAbiV2 from "./V2/Contest.json";
import contestAbiV3 from "./V3/ContestV3.json";

// Import other versions as needed

const factoryContractAbis = {
  "0x7b19611c6f0635b9e68b62ab993fee2a2d5e64c6": factoryAbiV1.abi,
  "0x7b19611c6f0635b9e68b62ab993fee2a2d5e64c6": factoryAbiV2.abi,
  "0x632d8E752933be8e9cA209dA34Ff417EFF680712": factoryAbiV3.abi,
  // Add other factory address to ABI mappings here
};

const contestContractAbis = {
  "0x7b19611c6f0635b9e68b62ab993fee2a2d5e64c6": contestAbiV1.abi,
  "0x7b19611c6f0635b9e68b62ab993fee2a2d5e64c6": contestAbiV2.abi,
  "0x632d8E752933be8e9cA209dA34Ff417EFF680712": contestAbiV3.abi,
  // Add other factory address to ABI mappings here
};

export { factoryContractAbis, contestContractAbis };
