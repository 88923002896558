import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginSection from "../LoginSection/LoginSection";
import NetworkSelector from "../NetworkSelector/NetworkSelector";
import styles from "./TopBar.module.css"; // Import the CSS module
import logoImage from "../../images/dank_my_meme.png";
import logoTitle from "../../images/dank_my_meme_title.png";
import hamburgerIcon from "../../images/burger_logo.svg"; // Add a hamburger icon

const TopBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={styles.topbar}>
      <div className={styles.topbarContainer}>
        <div className={styles.logoContainer}>
          <div className={styles.logoContainer2}>
            <Link to="/contestcreationpage">
              <img
                src={logoImage}
                alt="Dank My Meme Logo"
                className={styles.logo}
              />
            </Link>
            <Link to="/contestcreationpage" className={styles.deployLink}>
              DEPLOY
            </Link>
          </div>
        </div>

        <div className={styles.titleContainer}>
          <Link to="/">
            <img
              src={logoTitle}
              alt="Dank My Meme Title"
              className={styles.title}
            />
          </Link>

          
          <button className={styles.hamburgerButton} onClick={toggleMenu}>
            <img
              src={hamburgerIcon}
              alt="Menu"
              className={styles.hamburgerIcon}
            />
          </button>

          {/* Nav Links will disappear on mobile */}
          <div
            className={`${styles.navlinksContainer} ${
              menuOpen ? styles.showMenu : ""
            }`}
          >
            <Link to="/submissions" className={styles.navButton}>
              Submits
            </Link>
            <Link to="/" className={styles.navButton}>
              Contests
            </Link>
            <Link to="/votes" className={styles.navButton}>
              Votes
            </Link>
            <Link to="/create" className={styles.navButton}>
              Create
            </Link>
          </div>
        </div>

        <div className={styles.loginContainer}>
          <NetworkSelector />
          <LoginSection />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
