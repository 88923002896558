import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./NetworkSelector.module.css"; // Import CSS module
import sep_logo from "../../images/sep_logo.svg";
import eth_logo from "../../images/eth_logo.png";
import default_logo from "../../images/dank_my_meme.png"; // Add a default logo if needed

const NetworkSelector = () => {
  const [selectedNetwork, setSelectedNetwork] = useState(null); // Start with no network selected
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const networks = [
    { name: "Sepolia Testnet", chainId: "0xaa36a7", image: sep_logo },
    { name: "Ethereum Mainnet", chainId: "0x1", image: eth_logo },
    // Add more networks as needed
  ];

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const switchNetwork = async (chainId, network) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId }],
        });
        setSelectedNetwork(network);
        toast.success(`Switched to ${network.name} network`);
        setShowDropdown(false); // Close dropdown after selection
      } catch (error) {
        if (error.code === 4902) {
          toast.error(
            "Network not available in MetaMask. Please add it manually."
          );
        } else {
          console.error(`Failed to switch to ${network.name}`, error);
          toast.error(`Failed to switch network. Check console for details.`);
        }
      }
    } else {
      toast.warning(
        "MetaMask is not installed. Please install MetaMask to switch networks."
      );
    }
  };

  // Close dropdown if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        if (showDropdown) {
          setShowDropdown(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div className={styles.networkSelector}>
      <button
        ref={buttonRef}
        onClick={toggleDropdown}
        className={styles.networkSelectorButton}
      >
        {selectedNetwork ? (
          <img
            src={selectedNetwork.image}
            alt={selectedNetwork.name}
            className={styles.networkLogo}
          />
        ) : (
          <img
            src={default_logo} // Show a default logo or text when no network is selected
            alt="Select Network"
            className={styles.networkLogo}
          />
        )}
      </button>
      {showDropdown && (
        <>
        
        <ul
          ref={dropdownRef}
          className={`${styles.dropdownContent} ${
            showDropdown ? styles.show : ""
          }`}
        ><h3>Select a Network</h3>
          {networks.map((network) => (
            <li
              key={network.chainId}
              onClick={() => switchNetwork(network.chainId, network)}
              className={styles.accountItem}
            >
              <img
                src={network.image}
                alt={network.name}
                className={styles.dropdownNetworkLogo}
              />
              {network.name}
            </li>
          ))}
        </ul>
        </>
      )}
    </div>
  );
};

export default NetworkSelector;
