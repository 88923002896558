import { useCallback } from "react";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { factoryContractAbis } from "../contracts/abi/contractAbis";
import { useWallet } from "../contexts/WalletContext";

const useDeployContest = () => {
  const { selectedAccount, isWalletConnected } = useWallet();

  const deployNewContest = useCallback(
    async (formData) => {
      console.log("Starting contract deployment from the browser...");

      if (!window.ethereum) {
        toast.error(
          "Ethereum wallet is not available. Please connect and select an account in MetaMask."
        );
        console.error("Ethereum wallet not found.");
        return null;
      }

      if (!isWalletConnected || !selectedAccount) {
        toast.info("Please connect MetaMask and select an account.");
        return null;
      }

      if (!formData.factoryAddress) {
        toast.error("Contest factory address is not provided.");
        console.error("Contest factory address is not provided.");
        return null;
      }

      // Get the correct ABI for the provided factory address
      const factoryAbi = factoryContractAbis[formData.factoryAddress];
      console.log("Factory address:", formData.factoryAddress);
      console.log("Factory ABI:",factoryAbi);

      if (!factoryAbi) {
        toast.error("ABI for the provided factory address is not available.");
        console.error("ABI for the provided factory address is not available.");
        return null;
      }

      try {
        console.log("Requesting account access...");
        await window.ethereum.request({ method: "eth_requestAccounts" });

        console.log("Creating provider and signer...");
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        console.log("Signer Address:", signer);

        console.log("Creating factory contract...");
        const factoryContract = new ethers.Contract(
          formData.factoryAddress,
          factoryAbi,
          signer
        );

        console.log("Factory Contract Instance:", factoryContract);

        const startTimestamp = Math.floor(
          new Date(formData.startDateTime).getTime() / 1000
        );
        const endTimestamp = Math.floor(
          new Date(formData.endDateTime).getTime() / 1000
        );

        console.log("Form Data:", formData);
        console.log("Start Timestamp:", startTimestamp);
        console.log("End Timestamp:", endTimestamp);
        console.log(
          "Entry Fee:",
          ethers.parseUnits(formData.entryFee, 18).toString()
        );
        console.log(
          "Voting Fee:",
          ethers.parseUnits(formData.votingFee, 18).toString()
        );

        const tx = await factoryContract.createContest(
          formData.tokenAddress,
          formData.name,
          startTimestamp,
          endTimestamp,
          ethers.parseUnits(formData.entryFee, 18),
          ethers.parseUnits(formData.votingFee, 18),
          formData.winnerPercentage,
          formData.numberOfLuckyVoters
        );

        console.log("Transaction sent, waiting for confirmation...");
        const receipt = await tx.wait();
        console.log("Transaction confirmed:", receipt);

        // Extracting the contest address from the receipt logs
        const firstLog = receipt.logs[0];
        const contestAddress = firstLog ? firstLog.address : null;

        if (contestAddress) {
          console.log("Contest Address:", contestAddress);
          toast.success("New contest deployed successfully!");
          return contestAddress;
        } else {
          console.error("No logs found in the transaction receipt");
          toast.error(
            "Failed to extract contest address from the transaction receipt."
          );
          return null;
        }


      } catch (error) {
        console.error("Error deploying new contest:", error);
        toast.error("Failed to deploy new contest.");
        return null;
      }
    },
    [selectedAccount, isWalletConnected]
  );

  return { deployNewContest };
};

export default useDeployContest;
